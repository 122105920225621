import React, { useCallback, useMemo, useState } from "react";
import { graphql, Link } from "gatsby";
import "../styles/tag.scss";
import Layout from "../components/layoutnew";
import SEO from "../components/seo";
import defaultImg from "../images/opsio_contact.webp";
import { useLocation } from "@reach/router";
import BlogImage from "../components/resource/blog/BlogImage";
import Moment from "react-moment";

const TagPage = ({ data }) => {
  const location = useLocation();
  const mainCategory = location.pathname.split("/")[2];
  const subCategory = location.pathname.split("/")[3];

  const pageData = data.allStrapiTagPage.edges[0].node;
  const allIndustries = data.allIndustries.edges.filter(
    (item) => !!item.node.value
  );
  const allTechnologies = data.allTechnologies.edges.filter(
    (item) => !!item.node.value
  );
  const allServices = data.allServices.edges.filter(
    (item) => !!item.node.value
  );

  const allBlogs = data.allStrapiBlog.edges.map((item) => item.node);

  const categories = useMemo(() => {
    if (subCategory) {
      return [subCategory.replace("_", "/").replace("-", " ")];
    } else {
      if (mainCategory === "Service") {
        return allServices.map((service) => service.node.value);
      } else if (mainCategory === "Technology") {
        return allTechnologies.map((technology) => technology.node.value);
      } else {
        return allIndustries.map((industry) => industry.node.value);
      }
    }
  }, [subCategory, mainCategory, allServices, allTechnologies, allIndustries]);

  const taggedBlogs = useMemo(
    () => allBlogs.filter((blog) => categories.includes(blog.title)) || [],
    [allBlogs, categories]
  );

  const title = useMemo(() => {
    return subCategory
      ? subCategory.replace("_", "/").replace("-", " ")
      : mainCategory;
  }, [subCategory, mainCategory]);

  const [selectedBlogs, setSelectedBlogs] = useState(
    taggedBlogs.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 10)
  );
  const [sortBy, setSortBy] = useState(pageData.layout.sortOptions[0]);

  const fetchMore = useCallback(() => {
    setSelectedBlogs(taggedBlogs.slice(0, selectedBlogs.length + 10));
  }, [taggedBlogs, selectedBlogs]);

  const handleChangeSort = useCallback((e) => {
    setSortBy(e.target.value);
    if (e.target.value === pageData.layout.sortOptions[0]) {
      setSelectedBlogs((prev) =>
        prev.sort((a, b) => new Date(b.date) - new Date(a.date))
      );
    } else {
      setSelectedBlogs((prev) =>
        prev.sort((a, b) => a.viewCount - b.viewCount)
      );
    }
  }, []);

  return (
    <Layout slug={pageData.slug} locale={pageData.locale}>
      <SEO
        slug={pageData.slug}
        locale={pageData.locale}
        title={pageData.metaTitle}
        description={pageData.metaDes}
      />
      <div className="tagPageContainer">
        <div className="heroSection">
          <img src={defaultImg} alt="tagHero" />
          <div className="titleContainer">
            <h2>{`${title} ${pageData.layout.title}`}</h2>
            <h3>{`${pageData.layout.heading1} ${title} ${pageData.layout.heading2}`}</h3>
          </div>
        </div>
        <div className="filterSection">
          <div className="tagContainer">
            <h3>{pageData.layout.browse}</h3>
            <div className="tagsWrapper">
              {categories.map((category) => (
                <div key={category} className="tagItem">
                  {category}
                </div>
              ))}
            </div>
          </div>
          <div className="filterWrapper">
            <div>{pageData.layout.sortBy}</div>
            <select value={sortBy} onChange={handleChangeSort}>
              {pageData.layout.sortOptions.map((option) => (
                <option key={option} className="sortOption">
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="blogSection">
          {selectedBlogs.length > 0 ? (
            <>
              {selectedBlogs.map((node) => (
                <Link
                  to={`/resource/blog/${node.slug}`}
                  className="blogItem"
                  key={node.id}
                >
                  <div className="blogImage">
                    <BlogImage data={node} />
                  </div>
                  <div className="blogInfo">
                    <div className="blogDate">
                      <div className="date">
                        <Moment format="MMMM Do YYYY">{node.date}</Moment>
                      </div>
                      <div className="divider" />
                      <div className="time">{node.time}</div>
                    </div>
                    <h3>{node.heading1}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: node?.preview?.data?.preview,
                      }}
                      className="blogDescription"
                    ></div>
                  </div>
                </Link>
              ))}
              {selectedBlogs.length < taggedBlogs.length && (
                <div className="loadMoreContainer">
                  <div onClick={() => fetchMore()} className="loadMoreBtn">
                    Load More
                  </div>
                </div>
              )}
            </>
          ) : (
            <p className="noBlogs">No Blogs posted yet.</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default TagPage;

export const query = graphql`
  query TagPage($locale: String!) {
    allStrapiTagPage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          metaTitle
          metaDes
          locale
          layout {
            title
            browse
            sortBy
            filterBy
            heading1
            heading2
            loadMore
            relevantList
            sortOptions
          }
        }
      }
    }
    allIndustries: allStrapiIndustry {
      edges {
        node {
          id
          value
          blogs {
            id
            title
          }
        }
      }
    }
    allTechnologies: allStrapiTechnologyList {
      edges {
        node {
          value
          id
          blogs {
            id
            title
          }
        }
      }
    }
    allServices: allStrapiServiceList(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          value
          id
          blogs {
            id
            title
          }
        }
      }
    }

    allStrapiBlog(
      sort: { date: ASC }
      limit: 1000
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          locale
          slug
          createdAt
          date(formatString: "DD MMMM YYYY")
          time
          title
          heading1
          viewCount
          preview {
            data {
              preview
            }
          }
          id
          Author {
            name
          }
          blogimg {
            ext
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 210) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          detailDescription {
            data {
              detailDescription
            }
          }
        }
      }
    }
  }
`;
